import { environment } from '../environments/environment';
import { Identifier } from './shared/models/storage.models';

export class UrlConfig {
  public static get cmBaseUrl(): string {
    return (
      (environment.production
        ? sessionStorage.getItem(Identifier.TYPE_CMAPI_URL)
        : environment.cmApiUrl) || ''
    );
  }
  public static get coBaseUrl(): string {
    return (
      (environment.production
        ? sessionStorage.getItem(Identifier.TYPE_COAPI_URL)
        : environment.coApiUrl) || ''
    );
  }
}
